.work_container {
  height: calc(100vh - 68px);
  overflow-y: hidden;
  display: flex;
}

.work_left {
  flex: 55;
  /* border: 1px solid red; */
  max-width: 55%;
  border-right: 1px solid var(--hr);
  overflow-y: scroll;
}

.work_left::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.work_left {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.work_right {
  flex: 45;
  overflow-y: scroll;
  /* border: 1px solid red; */
}

.work_right::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.work_right {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.file_chips {
  height: 60px;
  display: flex;
  align-items: center;
  overflow-x: scroll;
  padding: 0 15px;
}

.document_frame {
  width: 100%;
  height: calc(100vh - 60px - 68px);
  border-radius: 15px 15px 0 0;
}

.work_chat_container {
  height: calc(100vh - 68px - 100px);
  overflow-y: scroll;
  /* border: 1px solid red; */
}

.work_form {
  min-height: 100px;
  /* border: 1px solid red; */
}

.input_container {
  display: flex;
  border: 2px solid var(--chat-input-border);
  /* align-items: center; */
  border-radius: 20px;
  width: 90%;
  min-height: 50px;
  margin: 5px 0 10px;
}

.input_container .input_area {
  flex: 100;
  min-height: 40px;
  padding: 13px 20px 0;
  font-family: "Nunito Sans", sans-serif;
  font-size: 18px;
  border: none;
  outline: none;
  border-radius: 20px;
  background-color: inherit;
  color: var(--text-color);
  /* border: 1px solid red; */
  resize: none;
}

.effective_input {
  border-radius: 20px !important;
  margin: 10px 0 !important;
  font-family: "Nunito Sans", sans-serif !important;
  line-height: 1.6 !important;
  font-size: 18px !important;
}

.MuiInputBase-root {
  align-items: flex-start !important;
}

.input_area::-webkit-scrollbar {
  display: none;
}

.effective_input::webkit-scrollbar {
  display: none !important;
}

/* Hide scrollbar for IE, Edge and Firefox */
.input_area,
.effective_input {
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}

.input_container_right {
  width: 50px;
  /* border: 1px solid red; */
  min-height: 50px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.auth_container {
  height: calc(100vh - 68px);
  overflow-y: scroll;
  display: flex;
  justify-content: center;
  align-items: center;
}

.auth_wrapper {
  border: 1px solid var(--hr);
  width: 30%;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 40vh;
  border-radius: 10px;
}

.auth_title {
  font-family: "Nunito Sans", sans-serif;
}

.upload_container {
  height: calc(100vh - 68px);
  overflow-y: scroll;
}

.upload_stage {
  width: 50%;
  min-width: 250px;
  margin: 20px auto;
}

.files_container,
.status_container {
  width: 50%;
  min-width: 250px;
  margin: 20px auto;
  /* border: 1px solid red; */
}

.files_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.nav_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--hr);
  padding: 0 10px;
  height: 68px;
  overflow-y: hidden;
}

.nav_right {
  display: flex;
  align-items: center;
}

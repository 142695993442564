.dash_container {
  height: calc(100vh - 68px);
  overflow-y: hidden;
}

.dash_wrapper {
  width: 70%;
  margin: 0 auto;
  overflow-x: scroll;
  overflow-y: hidden;
  min-width: 650px;
}

@media screen and (max-width: 1000px) {
  .dash_wrapper {
    width: 80%;
  }
}

.dash_header {
  /* border-bottom: 1px solid var(--hr); */
  /* padding: 0 20px; */
  /* border: 1px solid var(--background-color); */
  /* border: 1px solid inherit; */
  height: 150px;
}

.dash_title {
  font-family: "Nunito Sans", sans-serif;
  font-size: 24px;
  color: var(--light-text);
  /* border: 1px solid green; */
  height: 70px;
  margin: 0;
  display: flex;
  align-items: center;
}

.dash_search {
  display: flex;
  align-items: center;
  /* border: 1px solid green; */
  height: 80px;
}

.dash_search_container {
  display: flex;
  align-items: center;
  border: 1px solid var(--hr);
  flex: 1;
  margin: 0 15px 0 0;
  height: 40px;
  border-radius: 20px;
}

.dash_search_input {
  background-color: inherit;
  border: none;
  outline: none;
  font-family: "Nunito Sans", sans-serif;
  font-size: 16px;
  flex: 1;
  margin-right: 10px;
  color: var(--text-color);
}

.dash_document_list {
  border: 1px solid var(--hr);
  border-radius: 10px;
  padding: 0 10px;
  margin: 0 0 10px;
  height: calc(100vh - 150px - 68px - 10px);
  overflow-y: scroll;
}

.no_result_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.no_result_wrapper p {
  font-family: "Nunito", sans-serif;
  font-size: 18px;
}

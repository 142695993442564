.upload_item {
  cursor: pointer;
  min-height: 50px;

  /* border: 1px solid var(--background-color); */
  border: 1px solid var(--transparent);
  border-bottom: 1px solid var(--hr);
  transition: all 200ms ease-in-out;
}

.upload_item:hover {
  background-color: var(--background-hover);
  border-radius: 10px;
  /* border-bottom: 1px solid var(--background-color); */
}

.upload_item_row1 {
  display: flex;
  margin-top: 10px;
}

.upload_item_text {
  padding: 0;
  margin: 0;
  font-family: "Nunito Sans", sans-serif;
}

.upload_item_size,
.upload_item_date,
.upload_item_actions {
  /* border: 1px solid green; */
  width: 100px;
  display: flex;
  align-items: center;
}

.upload_item_actions {
  display: flex;
  align-items: center;
  justify-content: center;
}

.upload_item_files {
  display: flex;
  flex-wrap: wrap;
  width: 75%;
  padding: 0 0 0 10px;
  /* border: 1px solid green; */
}

.starter_container {
  height: calc(100vh - 68px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.starter_wrapper {
  width: 30%;
  min-width: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
  /* border: 1px solid black; */
}

:root {
  --dark: #1f1e1c;
  --black: #000000;
  --textColor: #fff;
  --pearlWhite: #e2dfd2;
  --lightBlue: #add8e6;
  --mattBlack: rgb(40, 40, 43);
  --onyx: rgb(53, 57, 53);
  --info: #0288d1;
  --pureWhite: #ffffff;
  --grey900: #212121;
  --grey800: #424242;
  --grey700: #616161;
  --grey600: #757575;
  --grey500: #9e9e9e;
  --grey400: #bdbdbd;
  --grey300: #e0e0e0;
  --grey200: #eee;
  --grey100: #f5f5f5;
  --grey50: #fafafa;
  --error: rgb(255, 51, 51);
  --backgroundDark: #121212;
}

html[data-theme="light"] {
  --background-color: var(--pureWhite);
  --text-color: var(--dark);
  --heading-color: var(--dark);
  --hr: var(--grey400);
  --scrollbar: var(--grey400);
  --chat-input-border: var(--grey300);
  --ai-chat-background: var(--grey100);
  --light-text: var(--grey600);
  --background-hover: var(--grey200);
  --transparent: rgba(0, 0, 0, 0);
  --primary: "#1976d2";
}

html[data-theme="dark"] {
  --background-color: var(--backgroundDark);
  --text-color: var(--pureWhite);
  --heading-color: var(--pureWhite);
  --hr: var(--grey300);
  --chat-input-border: var(--grey300);
  --scrollbar: var(--grey700);
  --ai-chat-background: var(--grey800);
  --light-text: var(--pureWhite);
  --background-hover: var(--grey800);
  --transparent: rgba(0, 0, 0, 0);
  --primary: "#90caf9";
}

::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}

/* Track */
::-webkit-scrollbar-track {
  /* background: var(--background-color); */
  background: var(--transparent);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--scrollbar);
  border-radius: 8px;
  transition: all 200ms ease;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--grey600);
}

.flex_center {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .container {
  border: 1px solid var(--text-color);
  height: 50px;
} */

/* Hide scrollbar */

/* .work_left::-webkit-scrollbar {
  display: none;
} */

/* Hide scrollbar for IE, Edge and Firefox */
/* .work_left {
  -ms-overflow-style: none; 
  scrollbar-width: none; 
} */

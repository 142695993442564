.file_container {
  border-radius: 10px;
  width: 250px;
  height: 70px;
  margin: 10px;
  display: flex;
  border: 1px solid var(--pureWhite);

  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.file_left {
  flex: 30;
}

.file_right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 65;
}

.file_title {
  margin: 0 0 5px 0;
  padding: 0;
  font-family: Nunito;
  font-size: 15px;
}

.file_size {
  margin: 0 0 0 0;
  padding: 0;
  font-family: Nunito;
  font-size: 13px;
}

.file_far_right {
  flex: 5;
  padding: 5px;
  /* border: 1px solid red; */
}

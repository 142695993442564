.bubble_container,
.ai_bubble_container {
  display: flex;
  justify-content: center;
}

.ai_bubble_container {
  background-color: var(--ai-chat-background);
}

.bubble_wrapper {
  display: flex;
  width: 90%;
  border: 1px xolid red;
}

.bubble_left {
  /* border: 1px solid red; */
  flex: 10;
  display: flex;
  justify-content: center;
}

.role_icon_cover {
  width: 30px;
  height: 30px;
  background-color: var(--text-color);
  border-radius: 5px;
  margin-top: 15px;
}

.bubble_right {
  /* border: 1px solid red; */
  flex: 90;
}

.bubble_paragraph {
  text-align: justify;
  font-family: "Nunito Sans", sans-serif;
  font-size: 18px;
  line-height: 1.6;
}

.bubble_loader {
  /* border: 1px solid red; */
  height: 60px;
  display: flex;
  align-items: center;
}

.sources_container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  min-height: 85px;
}

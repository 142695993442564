.profile_container {
  height: calc(100vh - 68px);
  overflow-y: scroll;
  display: flex;
  justify-content: center;
}

.profile_wrapper {
  width: 60%;
  /* border: 1px solid red; */
  display: flex;
}

.profile_left {
  flex: 30;
  /* border: 1px solid red; */
  display: flex;
  justify-content: center;
  padding-top: 50px;
}

.profile_right {
  flex: 70;
  padding-top: 50px;
  /* border: 1px solid red; */
}

.profile_item {
  /* border: 1px solid red; */
  margin-bottom: 20px;
  display: flex;
}

.profile_item_left {
  flex: 10;
}

.profile_item_right {
  flex: 90;
}

.profile_item h3,
.profile_item p {
  margin: 0;
  padding: 0;
  font-family: "Nunito", sans-serif;
}

.profile_item h3 {
  font-size: 20px;
}

.profile_item p {
  font-size: 18px;
}
